<template>

  <base-site :title="titleText" :show-button="!!subprojectId" button-icon="delete" button-look="secondary-light" @button-action="openModal">
    <base-container class="mb-betweenElements">
      <FormKit type="text" name="name" id="name" label="Name der Rechnung*" validation="required" v-model="subprojectName" />
      <FormKit type="number" number inputmode="decimal" step="any" name="costLimit" id="costLimit" label="Kostendach*" validation="required" :min="0" v-model="subprojectCostLimit" />
      <FormKit type="checkbox" label="Skonto" v-model="skontoCheckbox" />
      <div class="flex md:flex-row flex-col">
        <div class="md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" step="any" label="Skonto in %" :disabled="!skontoCheckbox" v-model="skonto"/></div>
        <FormKit type="date" name="date" id="date" label="Skonto Ablaufdatum" :disabled="!skontoCheckbox" v-model="skontoExpiration" />
      </div>

      <custom-fields ref="customFields"
                     :entity="CustomFieldEntity.SUBPROJECT"
                     :entity-id="subproject?.id ?? ''"
                     :custom-field-values="subproject?.customFieldValues" />

      <FormKit type="select" name="statusId" id="statusId" label="Rechnungsstatus*" validation="required"
               :options="Utils.toSelectOptions(Type.getAllStatuses())" v-model="subprojectStatusId" />

      <confirmation-modal ref="confirmDeletionModal"
                          title="Projekt löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll das Projekt wirklich gelöscht werden?</confirmation-modal>
    </base-container>

    <div class="flex md:flex-row flex-col">
      <base-button look="primary" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button look="secondary-light" class="md:mx-0 md:mt-0 mt-betweenElements mx-betweenElements md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>
  </base-site>

</template>

<script setup lang="ts">

import { API, PathSegment } from '@/client/axios'
import type Subproject from '@/model/Subproject'
import { Type } from '@/model/Type'
import { useRoute } from 'vue-router'
import router from '@/router'
import { onMounted, ref } from 'vue'
import { Utils } from '@/client/utils'
import BaseSite from '@/components/base/BaseSite.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import { DateConverter } from '@/client/DateConverter'
import CustomFields from '@/components/generel/CustomFields.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'

const SUBPROJECT = 'Rechnung';
const route = useRoute();

const requesting = ref(false);
const subprojectId = route.params.id as string;
const subproject = ref<Subproject>();
const projectId = route.query.projectId as string;
const titleText = ref('Rechnung');
const submitText = ref<string>();

const subprojectName = ref<string>();
const subprojectCostLimit = ref(0);
const skontoCheckbox = ref(false);
const skonto = ref(2);
const skontoExpiration = ref('');
const subprojectStatusId = ref<string>();

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

async function onSubmit() {
  if (!projectId && !subproject.value) {
    console.error('Subproject or project id is not set!');
    return;
  }

  const fields: any = {
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    customFields: getCustomFieldValues()
  }
  if (projectId) await onCreate(fields);
  else await onUpdate(fields);
}

async function onCreate(fields: any) {
  if (requesting.value) return;
  fields.projectId = projectId;
  requesting.value = true;
  const response = await API.createDataObject<Subproject>(PathSegment.SUBPROJECTS, fields, SUBPROJECT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate(fields: any) {
  if (requesting.value) return;
  fields.id = subprojectId;
  fields.projectId = subproject.value?.projectId;
  requesting.value = true;
  const response = await API.updateDataObject<Subproject>(PathSegment.SUBPROJECTS, fields, SUBPROJECT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.SUBPROJECTS, subprojectId, SUBPROJECT);
  if (success) {
    router.go(-1);
  }
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

async function loadSubproject() {
  const response = await API.getDataObject<Subproject>(PathSegment.SUBPROJECTS, subprojectId);
  if (!response) return;
  subproject.value = response;
  subprojectName.value = response.name;
  subprojectCostLimit.value = response.costLimit;
  subprojectStatusId.value = response.statusId;
  skontoCheckbox.value = response.skonto ? response.skonto != 0 : false;
  skonto.value = response.skonto ?? 0;
  skontoExpiration.value = response.skontoExpiration ? DateConverter.convertToLocalDateISO(response.skontoExpiration) : '';
}

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

onMounted(async () => {
  if (!subprojectId && !projectId) {
    console.error('Subproject or project id is not set!');
    return;
  }

  if (subprojectId) {
    titleText.value = 'Rechnung brearbeiten';
    submitText.value = 'Rechnung speichern';
    await loadSubproject();
  } else {
    titleText.value = 'Rechnung erstellen';
    submitText.value = 'Rechnung erfassen';
  }
});

</script>

<style scoped>

</style>