<template>
  <base-site :title="TenantSettings.isProjectOriented() ? 'Alle Projekte' : 'Kunden'"
             :show-button="KeycloakService.isManager()"
             button-icon="add"
             @button-action="TenantSettings.isProjectOriented() ? onAddProject() : onAddCustomer()">

    <base-container>

      <div v-if="showSearch" class="flex flex-row items-center">
        <!-- Search Field Container -->
        <div class="flex items-center bg-grey-mid rounded-lg overflow-hidden mb-betweenElements min-h-13 flex-grow">
          <span class="flex items-center px-3">
            <base-icon>search</base-icon>
          </span>
                <input type="text" class="w-full py-2 px-4 bg-grey-mid border-0 text-bodyLargeNormal focus:outline-none focus:ring-0" :placeholder="TenantSettings.isProjectOriented() ? 'Projektsuche' : 'Kundensuche'" v-model="search" @input="onSearch">
                <span v-if="search" class="flex items-center px-3 hover:cursor-pointer">
            <base-icon @click="onClearSearch">clear</base-icon>
          </span>
        </div>

        <!-- Select Field Container -->
        <div class="ml-betweenElements flex-shrink-0">
          <FormKit type="select" :options="Utils.toSelectOptions(Type.getAllStatuses(), 'Alle')" v-model="statusFilter" />
        </div>
      </div>

      <spinner v-if="projectsLoading"></spinner>

      <div v-else-if="projects.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Projekte vorhanden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddProject()">
          Neues Projekt erstellen
        </base-button>
      </div>

      <div v-else>
          <div v-for="(project, index) of projects" :key="project.id"
               class="space-x-betweenElements border border-grey-mid rounded-sm px-8 py-5 hover:shadow-inner transition-all ease-in-out flex justify-between items-center group hover:border-primary cursor-pointer container"
               :class="{'mb-betweenElements': projects.length - 1 != index}"
               @click="onViewProject(project.id)">
            <div class="flex flex-col w-full">
              <div class="flex items-center space-x-[4px] min-w-0 pb-2">
                <div class="text-bodySmallNormal whitespace-nowrap overflow-hidden text-ellipsis flex-1 min-w-0">
                  {{ TenantSettings.isCustomerOriented() ? Utils.getCustomerOneLineAddress(project.customer) : Utils.getCustomerTitle(project.customer) }}
                </div>
                <p class="text-bodySmallBold flex-shrink-0 ml-2" :style="{ color: project.statusId ? Type.getStatus(project.statusId).displayColor : '#ffffff' }">
                  {{ project.statusId ? Type.getStatus(project.statusId).name : '' }}
                </p>
              </div>
              <p class="text-bodyMediumBold group-hover:text-primary whitespace-nowrap overflow-hidden text-ellipsis min-w-0">{{ project.name }}</p>
            </div>
          </div>

      </div>

    </base-container>

  </base-site>
</template>

<script setup lang="ts">

import { KeycloakService } from '@/service/keycloakService'
import { Type } from '@/model/Type'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Spinner from '@/components/generel/Spinner.vue'
import { useRouter } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
import type Project from '@/model/Project'
import { useToast } from 'vue-toast-notification'
import BaseSite from '@/components/base/BaseSite.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { routeNames } from '@/router'
import { TenantSettings } from '@/stores/TenantSettings'

const router = useRouter();

const projects = ref<Project[]>([]);
const projectsLoading = ref(true);

const showSearch = ref(false);
const search = ref('');
const statusFilter = ref('');

const $toast = useToast();

async function onViewProject(id: string) {
  await router.push({name: routeNames.PROJECT, params: {id: id}});
}

async function onAddProject() {
  await router.push({name: routeNames.CREATE_PROJECT});
}

async function onAddCustomer() {
  await router.push({name: routeNames.CREATE_CUSTOMER});
}

async function onSearch() {
  const term = search.value;
  await delay(500);
  if (term != search.value) return;

  const filteredProjects = await getFilteredProjects(new Map<string,string>([['includeCustomer', 'true'], ['limit', '10000000'], ['searchTerm', term], ['statusId', statusFilter.value]]));
  if (term != search.value) return;
  projects.value = filteredProjects;
}

watch(() => statusFilter.value, async newVal => {
  if (!newVal) newVal = '';
  await setProjectsWithStatusFilter(newVal);
}, {
  immediate: true
});

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

function onClearSearch() {
  search.value = '';
  if (statusFilter.value) {
    setProjectsWithStatusFilter(statusFilter.value);
    return;
  }
  loadProjects();
}

async function setProjectsWithStatusFilter(statusId: string) {
  const filteredProjects = await getFilteredProjects(new Map<string,string>([['includeCustomer', 'true'], ['limit', '10000000'], ['searchTerm', search.value], ['statusId', statusId]]));
  if (statusId != statusFilter.value) return;
  projects.value = filteredProjects;
}

async function getFilteredProjects(parameters: Map<string, string>): Promise<Project[]> {
  projectsLoading.value = true;
  try {
    const response = await API.getWithParameters<Project[]>(PathSegment.PROJECTS, parameters);
    projectsLoading.value = false;
    if (response.key == ResponseKey.OK) return response.data;
    else {
      $toast.error(`Filterungsfehler: ${response.message}`);
      return [];
    }
  } catch (error) {
    $toast.error(`Filterungsfehler: ${Utils.getError(error)}`);
    projectsLoading.value = false;
    return [];
  }
}

async function loadProjects() {
  try {
    projectsLoading.value = true;
    const response = await API.getWithParameters<Project[]>(PathSegment.PROJECTS, new Map<string,string>([['includeCustomer', 'true'], ['limit', '10000000']]));
    if (response.key == ResponseKey.OK) {
      projects.value = response.data;
      projectsLoading.value = false;
      if (response.data.length > 0) showSearch.value = true;
    } else {
      $toast.error(`Projekte konnten nicht geladen werden: ${response.message}`);
      console.error(`Projekte could not be loaded: ${response.message}`);
    }
  } catch (error) {
    $toast.error(`Projekte konnten nicht geladen werden: ${Utils.getError(error)}`);
    console.error(`Projekte could not be loaded: ${Utils.getError(error)}`);
  }
}

onMounted(() => {
  loadProjects();
})
</script>

<style scoped>

</style>