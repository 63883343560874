<template>

  <base-site :title="titleText" :show-button="!!reportPositionId" button-icon="delete" button-look="secondary-light" @button-action="openModal">

    <spinner v-if="reportPositionLoading" />

    <template v-else>

      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <base-icon>access_time_filled</base-icon>
          </div>
          Allgemein
        </base-heading>
        <FormKit type="date" name="date" id="date" label="Datum*" validation="required" v-model="date" />

        <custom-fields ref="customFields"
                       :entity="CustomFieldEntity.MATERIAL_REPORT_POSITION"
                       :entity-id="existingReportPosition?.id ?? ''"
                       :custom-field-values="existingReportPosition?.customFieldValues" />

      </base-container>

      <transport-locations-card v-if="TenantSettings.isTransport()"
                                :existing-description="existingReportPosition?.description ?? ''"
                                @receive-locations="receiveCategoryFromCategoryCard"
                                class="mb-betweenElements"/>
      <material-category-card v-else ref="categoryCard"
                              :existing-category="existingReportPosition?.category"
                              :existing-description="existingReportPosition?.description ?? ''"
                              @receive-category="receiveCategoryFromCategoryCard"
                              class="mb-betweenElements" />

      <transport-info-card v-if="TenantSettings.isTransport()"
                          :existing-material-report-position="existingReportPosition"
                          @update-price="receivePriceInfos"
                          class="mb-betweenElements" />
      <material-info-card v-else-if="category || description"
                          :category="category"
                          :existing-material-report-position="existingReportPosition"
                          @update-price="receivePriceInfos"
                          class="mb-betweenElements" />

      <div class="flex md:flex-row flex-col">
        <base-button v-if="category || description" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
          <spinner-small v-if="requesting" />
          <div v-else>{{submitText}}</div>
        </base-button>
        <base-button :class="`md:ml-auto md:mx-0 mx-betweenElements md:w-buttonXLarge ${category || description ? 'mt-8 md:mt-0' : ''}`" look="secondary-light" @click="onCancel">
          Abbrechen
        </base-button>
      </div>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Materialposition Löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll die Materialposition wirklich gelöscht werden?
      </confirmation-modal>

    </template>
  </base-site>

</template>

<script setup lang="ts">

import { nextTick, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import router from '@/router'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import Spinner from '@/components/generel/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import MaterialCategoryCard from '@/components/materialReportPosition/materialCategoryCard.vue'
import type { MaterialReportPositionRequest, MaterialReportPositionResponse } from '@/model/MaterialReportPosition'
import type Category from '@/model/Category'
import MaterialInfoCard from '@/components/materialReportPosition/materialInfoCard.vue'
import { DateConverter } from '@/client/DateConverter'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import TransportLocationsCard from '@/components/materialReportPosition/transportLocationsCard.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import TransportInfoCard from '@/components/materialReportPosition/transportInfoCard.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import CustomFields from '@/components/generel/CustomFields.vue'

const MATERIAL_REPORT = TenantSettings.isTransport() ? 'Transport' : 'Materialposition';
const route = useRoute();

const requesting = ref(false);
const reportPositionLoading = ref(true);
const reportPositionId = route.params.id as string;
const subprojectId = route.query.subprojectId as string;
const projectId = route.query.projectId as string;
const existingReportPosition = ref<MaterialReportPositionResponse>();
const date = ref(DateConverter.getCurrentLocalDateISO())

const titleText = ref(MATERIAL_REPORT);
const submitText = ref<string>();

const categoryCard = ref(null);
const category = ref<Category | null>(null);
const description = ref('');

const amount = ref(0);
const selectedUnitId = ref('');
const unitPrice = ref(0);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

function receiveCategoryFromCategoryCard(categoryIds: Category, descriptionText: string) {
  category.value = categoryIds;
  description.value = descriptionText;
}

function receivePriceInfos(infos: {amount: number, unitId: string, unitPrice: number}) {
  amount.value = infos.amount;
  selectedUnitId.value = infos.unitId;
  unitPrice.value = infos.unitPrice;
}

async function onSubmit() {
  if (!category.value && description.value == '') {
    $toast.error('Keine Kategorie oder Beschreibung hinzugefügt');
    return;
  }
  if (amount.value == 0 || !selectedUnitId.value || unitPrice.value == 0) {
    $toast.error('Preisangaben nicht korrekt');
    return;
  }
  if (!subprojectId) {
    $toast.error('Keine Rechnung zugewiesen');
    console.error('subproject not selected!');
    return;
  }
  if (reportPositionId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.createDataObject<MaterialReportPositionResponse, MaterialReportPositionRequest>(
    PathSegment.MATERIAL_REPORT_POSITIONS,
    {
        id: '',
        subprojectId: subprojectId,
        date: DateConverter.convertToUnixTimestamp(date.value),
        description: description.value,
        quantity: amount.value,
        unitId: selectedUnitId.value,
        unitPrice: unitPrice.value,
        categoryId: category.value?.id ?? null,
        customFieldValues: getCustomFieldValues()
      }, MATERIAL_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  if (!reportPositionId) {
    $toast.error('Aktuallisieren nicht möglich, keine Raport ID vorhanden');
    console.error('Update failed: ReportPositionId not set');
    return;
  }

  requesting.value = true;
  const response = await API.updateDataObject<MaterialReportPositionResponse, MaterialReportPositionRequest>(
    PathSegment.MATERIAL_REPORT_POSITIONS,
    {
      id: reportPositionId,
      subprojectId: subprojectId,
      date: DateConverter.convertToUnixTimestamp(date.value),
      description: description.value,
      quantity: amount.value,
      unitId: selectedUnitId.value,
      unitPrice: unitPrice.value,
      categoryId: category.value?.id ?? null,
      customFieldValues: getCustomFieldValues()
    }, MATERIAL_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function loadReportPosition() {
  const response = await API.getDataObject<MaterialReportPositionResponse>(PathSegment.MATERIAL_REPORT_POSITIONS, reportPositionId)
  if (!response) return;
  existingReportPosition.value = response;
  date.value = DateConverter.convertToLocalDateISO(response.date);
  category.value = response.category;
  description.value = response.description;

  reportPositionLoading.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.MATERIAL_REPORT_POSITIONS, reportPositionId, MATERIAL_REPORT);
  if (success) router.go(-1);
  reportPositionLoading.value = false;
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

onMounted(async () => {
  if (reportPositionId) {
    // edit
    titleText.value = `${MATERIAL_REPORT} bearbeiten`;
    submitText.value = 'Speichern';
    await loadReportPosition();
  } else {
    // create
    titleText.value = `${MATERIAL_REPORT} erstellen`;
    submitText.value = 'Erfassen';
    reportPositionLoading.value = false;
  }
});

</script>

<style scoped>

</style>