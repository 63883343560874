<template>

  <base-site title="Kunden" :show-button="KeycloakService.isManager()" button-icon="add" @button-action="onAddCustomer">
    <base-container>

      <div v-if="showSearch" class="flex items-center bg-grey-mid rounded-lg overflow-hidden mb-betweenElements min-h-13">
        <span class="flex items-center px-3">
            <base-icon>search</base-icon>
        </span>
        <input type="text" class="w-full py-2 px-4 bg-grey-mid border-0 text-bodyLargeNormal focus:outline-none focus:ring-0" placeholder="Kundensuche" v-model="search" @input="onSearch">
        <span v-if="search" class="flex items-center px-3 hover:cursor-pointer">
            <base-icon @click="onClearSearch">clear</base-icon>
        </span>
      </div>

      <spinner v-if="customersLoading" />

      <div v-else-if="customers.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Kunden vorhanden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddCustomer()">
          Neuer Kunde erstellen
        </base-button>
      </div>

      <div v-else>
        <div v-for="(customer, index) in customers" :key="index"
             class="selectable flex flex-col px-8 py-5" :class="{'mb-betweenElements': !(customers.length - 1 == index)}"
             @click="onViewCustomer(customer.id)">
          <p class="text-bodyMediumBold pb-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
            {{Utils.getCustomerTitle(customer)}}
          </p>
          <p class="text-bodySmallNormal whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
            {{customer.address.street}}, {{customer.address.zip}} {{customer.address.city}}
          </p>
        </div>
      </div>

    </base-container>
  </base-site>

</template>

<script setup lang="ts">
import { loadCustomers } from '@/service/customerService'
import { onMounted, ref } from 'vue'
import type Customer from '@/model/Customer'
import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import Spinner from '@/components/generel/Spinner.vue'
import { routeNames } from '@/router'
import { useRouter } from 'vue-router'
import BaseButton from '@/components/base/BaseButton.vue'
import { Utils } from '../../client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { TenantCompany } from '@/stores/TenantCompany'
import { useToast } from 'vue-toast-notification'
import { KeycloakService } from '@/service/keycloakService'

const router = useRouter();
const customers = ref<Customer[]>([]);
const customersLoading = ref(true);

const showSearch = ref(false);
const search = ref('');

const $toast = useToast();

function onAddCustomer() {
  router.push({name: routeNames.CREATE_CUSTOMER});
}

function onViewCustomer(id: string) {
  router.push({name: routeNames.CUSTOMER, params: {id: id}});
}

async function onSearch() {
  const term = search.value;
  await delay(500);
  if (term != search.value) return;
  await displaySearchedCustomers(term);
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

function onClearSearch() {
  search.value = '';
  loadData();
}

async function loadData() {
  customersLoading.value = true;
  const data = await loadCustomers();
  if (data) {
    customers.value = data;
    if (data.length > 0) showSearch.value = true;
  }
  customersLoading.value = false;
}

async function displaySearchedCustomers(searchTerm: string) {
  customersLoading.value = true;
  try {
    const response = await API.getWithParameters<Customer[]>(PathSegment.COMPANIES, TenantCompany.getCompany().id, PathSegment.CUSTOMERS, new Map<string,string>([['searchTerm', searchTerm]]));
    if (response.key == ResponseKey.OK) {
      if (search.value == searchTerm)
      customers.value = response.data;
    } else {
      $toast.error(`Suchanfragen Fehler: ${response.message}`);
    }
  } catch (error) {
    $toast.error(`Suchanfragen Fehler: ${Utils.getError(error)}`);
  }
  customersLoading.value = false;
}

onMounted(() => {
  loadData();
});

</script>

<style scoped>

</style>