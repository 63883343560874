<template>

  <base-container>
    <div class="flex flex-row">
      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>location_on</base-icon>
        </div>
        Lade- & Abladeort
      </base-heading>
      <button class="ml-auto" @click="openTranscriptionModal">
        <base-icon size="iconSizeLarge">mic</base-icon>
      </button>
    </div>

    <FormKit type="text" label="Ladeort" v-model="pickupLocation" />
    <FormKit type="text" label="Abladeort" v-model="dropLocation" />

    <transcription-modal ref="transcriptionModalRef"
                         existing-text=""
                         @on-transcribed="onTranscribed" />

  </base-container>

</template>

<script setup lang="ts">

import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { ref, watch } from 'vue'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'
import { Utils } from '@/client/utils'

const props = defineProps<{
  existingDescription: string;
}>();

const emits = defineEmits(['receive-locations']);

const pickupLocation = ref('');
const dropLocation = ref('');

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  const split = getPickupAndDropByText(text);
  pickupLocation.value = split.pickup;
  dropLocation.value = split.drop;
}

watch(() => props.existingDescription, newVal => {
  if (!newVal) return;
  const locations = Utils.getPickupAndDropLocation(newVal);
  pickupLocation.value = locations.pickup;
  dropLocation.value = locations.drop;
}, {
  immediate: true
});

watch(() => pickupLocation.value, newVal => {
  if (newVal == undefined) return;
  emitData()
}, {
  immediate: true
});

watch(() => dropLocation.value, newVal => {
  if (newVal == undefined) return;
  emitData()
}, {
  immediate: true
});

function emitData() {
  emits('receive-locations', [], `;;;${pickupLocation.value};;;${dropLocation.value}`);
}

function getPickupAndDropByText(text: string) {
  if (text.includes(' bis nach ')) return splitPickupAndDropByKeyword(text, ' bis nach ');
  if (text.includes(' nach ')) return splitPickupAndDropByKeyword(text, ' nach ');
  if (text.includes(' bis ')) return splitPickupAndDropByKeyword(text, ' bis ');
  if (text.includes(' zu ')) return splitPickupAndDropByKeyword(text, ' zu ');
  if (text.includes(' Nach ')) return splitPickupAndDropByKeyword(text, ' Nach ');
  if (text.includes(' Bis ')) return splitPickupAndDropByKeyword(text, ' Bis ');
  if (text.includes(' Bis nach ')) return splitPickupAndDropByKeyword(text, ' Bis nach ');
  if (text.includes(' Zu ')) return splitPickupAndDropByKeyword(text, ' Zu ');
  else return {pickup: text, drop: ''};
}

function splitPickupAndDropByKeyword(text: string, keyword: string) {
  const split = text.split(keyword);
  if (split.length < 2) return {pickup: removeStartingVon(text), drop: ''};
  return {pickup: removeEndingDot(removeStartingVon(split[0])), drop: removeEndingDot(split[1])}
}

function removeEndingDot(str: string): string {
  if (str.endsWith('.')) {
    return str.slice(0, -1);
  }
  return str;
}

function removeStartingVon(str: string): string {
  const prefix = 'Von';
  if (str.startsWith(prefix)) {
    return str.slice(prefix.length).trim();
  }
  return str;
}

</script>

<style scoped>

</style>