<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>engineering</base-icon>
        </div>
        Arbeitsrapport
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddWorkReportPosition()">
        <base-icon>add</base-icon>
      </base-button>
    </div>

    <spinner v-if="positionsLoading"></spinner>

    <div v-else>
      <div v-if="tableWorkReportPositions && tableWorkReportPositions.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>
      <div v-else>

        <div class="grid gap-4">
          <!-- Header -->
          <div class="bg-grey-mid p-4 md:rounded-md rounded border border-grey-mid grid md:grid-cols-80 grid-cols-8 gap-4">
            <div class="col-span-3 md:block hidden text-right"></div>
            <div class="col-span-11 md:block hidden">Datum</div>
            <div class="md:col-span-44 col-span-6">
              <div class="md:hidden block text-bodyMediumBold">Datum</div>
              Tätigkeit
            </div>
            <div class="col-span-10 md:block hidden">Total Aufwand</div>
            <div class="md:col-span-12 col-span-2 text-right">
              <div class="md:hidden text-bodyMediumBold">Aufwand</div>
              <div class="md:hidden">Kosten</div>
              <div class="md:block hidden">Totale Kosten</div>
            </div>
          </div>
          <!-- Content -->
          <div v-for="(cell, index) in tableWorkReportPositions" :key="index">
            <div class="grid md:grid-cols-80 grid-cols-8 gap-4" :class="dynamicStyle(cell)">
              <div class="col-span-3 md:block hidden overflow-hidden py-4 pl-4 transition-colors hover:text-primary cursor-pointer" @click="toggleVisibility(index)">
                <base-icon>
                  {{cell.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                </base-icon>
              </div>
              <div class="col-span-11 md:block hidden overflow-hidden py-4 md:pl-0 pl-4" @click="onEditWorkReportPosition(cell.position.id)">{{ DateConverter.convertToLocalDate(cell.position.date) }}</div>
              <div class="md:col-span-44 col-span-6 py-4 md:pl-0 pl-4" @click="onEditWorkReportPosition(cell.position.id)">
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">{{ DateConverter.convertToLocalDate(cell.position.date) }}</div>
                {{ cell.position.invoiceInscription }}
              </div>
              <div class="col-span-10 md:block hidden overflow-hidden py-4" @click="onEditWorkReportPosition(cell.position.id)">{{ calculateTotalHours(cell.position.workers)
                }} {{ Type.getImmutableUnit(Units.HOURS).abbreviation() }}</div>
              <div class="md:col-span-12 col-span-2 text-right overflow-hidden py-4 pr-4" @click="onEditWorkReportPosition(cell.position.id)">
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">{{ calculateTotalHours(cell.position.workers)
                  }} {{ Type.getImmutableUnit(Units.HOURS).abbreviation() }}</div>
                <div class="md:hidden overflow-hidden">{{ Utils.formatCurrency(calculateTotalCosts(cell.position.workers)) }}</div>
                <div class="md:block hidden overflow-hidden">{{ Utils.formatCurrency(calculateTotalCosts(cell.position.workers)) }}
                  {{ Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation() }}</div>
              </div>
            </div>

            <div class="grid border-primary border-b border-r border-l rounded-b-md hover:cursor-pointer" :class="{'hidden': !cell.visible}" @click="toggleVisibility(index)">
              <!-- Header -->
              <div class="bg-grey-light p-4 grid md:grid-cols-20 grid-cols-8 border-t border-grey-mid">
                <div class="col-span-1 overflow-hidden"></div>
                <div class="col-span-10 overflow-hidden">Name</div>
                <div class="col-span-3 overflow-hidden">Stunden</div>
                <div class="col-span-3 overflow-hidden">Preis pro Stunde</div>
                <div class="col-span-3 text-right overflow-hidden">Total</div>

              </div>
              <!-- Content -->
              <div v-for="worker in cell.position.workers" :key="worker.name" class="grid md:grid-cols-20 grid-cols-8 p-4 border-t border-grey-mid">
                <div class="col-span-1 overflow-hidden">{{ worker.quantity }} x</div>
                <div class="col-span-10 overflow-hidden">{{ worker.name }}</div>
                <div class="col-span-3 overflow-hidden">{{ worker.hoursWorked }}
                  {{ Type.getImmutableUnit(Units.HOURS).abbreviation() }}</div>
                <div class="col-span-3 overflow-hidden">{{ Utils.formatCurrency(worker.pricePerHour) }}
                  {{ Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation() }}/{{ Type.getImmutableUnit(Units.HOURS).abbreviation() }}</div>
                <div class="col-span-3 text-right overflow-hidden">{{ Utils.formatCurrency(worker.total) }}
                  {{ Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation() }}</div>
              </div>
            </div>

          </div>

          <div v-if="totalPages > 1" class="flex flex-row ml-auto">
            <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
            <div class="cursor-default">{{offset + 1}} / {{totalPages}}</div>
            <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
          </div>
        </div>

      </div>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import router, { routeNames } from '@/router'
import { Units, Type } from '@/model/Type'
import type { Worker, WorkReportPositionMinimal } from '@/model/WorkReportPosition'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { DateConverter } from '@/client/DateConverter'
import Spinner from '@/components/generel/Spinner.vue'
import { getWorkReportPage } from '@/service/workReportService'
import { Utils } from '../../client/utils'

const props = defineProps<{
  subprojectId: string;
  projectId: string;
  locked: boolean;
}>();

const pageSize = 10;
const totalPages = ref(0);
const positionsLoading = ref(false);
const tableWorkReportPositions = ref<{position: WorkReportPositionMinimal, visible: boolean}[]>();
const offset = ref(0);

function dynamicStyle(cell: {position: WorkReportPositionMinimal, visible: boolean}) {
  if (cell.visible)                   return 'border-t border-l border-r border-primary rounded-t-md hover:cursor-pointer';
  if (props.locked && !cell.visible)  return 'unselectable-md px-0 py-0';
  if (!props.locked && !cell.visible) return 'selectable-md px-0 py-0';
  return '';
}

async function onEditWorkReportPosition(id: string) {
  if (props.locked) return;
  await router.push({name: routeNames.EDIT_WORK_REPORT, params: {id: id}, query: {subprojectId: props.subprojectId, projectId: props.projectId}})
}

async function onAddWorkReportPosition() {
  await router.push({name: routeNames.CREATE_WORK_REPORT, query: {subprojectId: props.subprojectId, projectId: props.projectId}})
}

function calculateTotalHours(workers: Worker[]) {
  return workers.reduce((sum, worker) => sum + worker.hoursWorked, 0);
}

function calculateTotalCosts(workers: Worker[]) {
  return workers.reduce((sum, worker) => sum + worker.total, 0);
}

function toggleVisibility(index: number): void {
  if (!tableWorkReportPositions.value) return;
  for (let i = 0; i < tableWorkReportPositions.value?.length; i++) {
    if (i == index) tableWorkReportPositions.value[i].visible = !tableWorkReportPositions.value[i].visible
    else tableWorkReportPositions.value[i].visible = false;
  }
}

async function onNextPage() {
  if (offset.value + 1 >= totalPages.value) return;
  offset.value += 1;
  await loadWorkReportPositions();
}

async function onPreviousPage() {
  if (offset.value == 0) return;
  offset.value -= 1;
  await loadWorkReportPositions()
}

async function loadWorkReportPositions(setLoading: boolean = false) {
  if (setLoading) positionsLoading.value = true;
  const response = await getWorkReportPage(props.subprojectId, pageSize, offset.value);
  positionsLoading.value = false;
  if (!response) return;
  totalPages.value = response.totalPages;
  tableWorkReportPositions.value = response.page.map(w => {
    return {
      position: w,
      visible: false
    }
  })
}

watch(() => props.subprojectId, newVal => {
  if (!newVal) return;
  offset.value = 0;
  loadWorkReportPositions(true);
}, {
  immediate: true
});

</script>

<style scoped>

</style>