<template>
  <base-site :title="customer ? Utils.getCustomerTitle(customer) : ''" :show-button="KeycloakService.isManager()" button-icon="edit" @button-action="onEditCustomer">
    <base-container class="mb-outerFrameBorder">

      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>home</base-icon>
        </div>
        Kundenanschrift
      </base-heading>

      <div v-if="customer">
        <div>{{customer.companyName}}</div>
        <div>{{customer.title}} {{customer.firstname}} {{customer.lastname}}</div>
        <div>{{customer.address.street}}</div>
        <div>{{customer.address.zip}} {{customer.address.city}} {{customer.address.state}} {{customer.address.country}}</div>
      </div>
      <div v-else>Keine Anschrift</div>

    </base-container>
    <base-container>

      <div class="flex justify-between mb-5">
        <base-heading>
          <div class="title-icon-with-background">
            <base-icon>task</base-icon>
          </div>
          Projekte
        </base-heading>
        <base-button v-if="KeycloakService.isManager() && projects.length > 0" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddProject()">
          <base-icon>add</base-icon>
        </base-button>
      </div>


      <spinner v-if="projectsLoading"></spinner>

      <div v-else-if="projects.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Projekte vorhanden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddProject()">
          Neues Projekt erstellen
        </base-button>
      </div>

      <div v-else>
        <div v-for="(project, index) of projects" :key="index"
             class="selectable flex flex-col px-8 py-5" :class="{'mb-betweenElements': !(projects.length - 1 == index)}"
             @click="onViewProject(project.id)">
          <p class="text-bodySmallBold pb-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0" :style="{ color: project.statusId ? Type.getStatus(project.statusId).displayColor : '#ffffff' }">
            {{Type.getStatus(project.statusId).name}}
          </p>
          <p class="text-bodyMediumBold whitespace-nowrap overflow-hidden text-ellipsis min-w-0">{{project.name}}</p>
        </div>
      </div>

    </base-container>
  </base-site>
</template>

<script setup lang="ts">
import Spinner from '@/components/generel/Spinner.vue'
import { onMounted, ref } from 'vue'
import BaseSite from '@/components/base/BaseSite.vue'
import { useRoute, useRouter } from 'vue-router'
import type Customer from '@/model/Customer'
import type Project from '@/model/Project'
import { routeNames } from '@/router'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { Type } from '../../model/Type'
import DashboardProjectCard from '@/components/dashboard/DashboardProjectCard.vue'
import { Utils } from '@/client/utils'
import { KeycloakService } from '@/service/keycloakService'

const route = useRoute();
const router = useRouter();
const customerId = route.params.id as string;

const customer = ref<Customer>();
const projects = ref<Project[]>([]);

const projectsLoading = ref(true);
const $toast = useToast();

function onEditCustomer() {
  router.push({name: routeNames.EDIT_CUSTOMER, params: {id: customerId}});
}

function onAddProject() {
  router.push({name: routeNames.CREATE_PROJECT, query: {customerId: customerId}});
}

function onViewProject(id: string) {
  router.push({name: routeNames.PROJECT, params: {id: id}});
}

async function loadCustomer() {
  try {
    const response = await API.get<Customer>(PathSegment.CUSTOMERS, customerId);
    if (response.key == ResponseKey.OK) {
      customer.value = response.data;
    } else {
      $toast.error(`Kunde konnte nicht geladen werden: ${response.message}`);
      console.error(`Customer could not be loaded: ${response.message}`)
    }
  } catch (error) {
    $toast.error(`Kunde konnte nicht geladen werden`);
    console.error(`Project could not be loaded: ${error}`);
  }
}

async function loadProjects() {
  try {
    const response = await API.get<Project[]>(PathSegment.CUSTOMERS, customerId, PathSegment.PROJECTS);
    if (response.key == ResponseKey.OK) {
      projects.value = response.data;
    } else {
      $toast.error(`Projekte konnte nicht geladen werden: ${response.message}`);
      console.error(`Subprojects could not be loaded: ${response.message}`)
    }
    projectsLoading.value = false;
  } catch (error) {
    $toast.error(`Projekte konnte nicht geladen werden`);
    console.error(`Subprojects could not be loaded: ${error}`);
  }
}

onMounted(() => {
  loadCustomer();
  loadProjects();
});

</script>

<style scoped>

</style>