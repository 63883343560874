<template>
  <base-container>

    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>engineering</base-icon>
      </div>
      Arbeitervolagen
    </base-heading>

    <spinner v-if="loading"></spinner>

    <div v-else>
      <FormKit type="select" label="Vorlage" :options="presetOptions" v-model="selectedPresetId" />
      <FormKit type="text" label="Name*" validation="required" v-model="name" />
      <FormKit type="number" number step="any" label="Preis pro Stunde*" inputmode="decimal" validation="required" v-model="pricePerHour" />
      <FormKit type="number" number step="any" label="Anzahl Arbeiter*" inputmode="decimal" validation="required" v-model="quantity" />
      <FormKit type="checkbox" label="Wird vorausgewählt*" validation="required" v-model="defaultSelected" />

      <div class="flex md:flex-row flex-col">
        <base-button class="md:mr-auto md:mb-0 mb-8 md:w-buttonXLarge" @click="onSubmit">
          <base-icon v-if="!requesting" class="mr-5">save</base-icon>
          <spinner-small v-if="requesting" />
          <div v-else>{{selectedPresetId == '' ? 'Erstellen' : 'Speichern'}}</div>
        </base-button>
        <base-button class="md:ml-auto md:w-buttonXLarge" look="secondary" v-if="selectedPresetId != ''" @click="openModal">
          <base-icon class="mr-5">delete</base-icon>
          Löschen
        </base-button>
      </div>
    </div>

    <confirmation-modal ref="confirmDeletionModal"
                        title="Arbeitervorlage löschen"
                        confirm-text="Löschen"
                        @on-confirm="onDeleteWorkerPreset">
      Soll die Arbeitervorlage wirklich gelöscht werden?
    </confirmation-modal>

  </base-container>
</template>

<script setup lang="ts">

import BaseContainer from '@/components/base/BaseContainer.vue'
import Spinner from '@/components/generel/Spinner.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { onMounted, ref, watch } from 'vue'
import { API, PathSegment } from '@/client/axios'
import type WorkerPreset from '@/model/WorkerPreset'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'

const loading = ref(true);
const presetOptions = ref<{label: string, value: string}[]>([]);
const existingWorkerPresets = ref<WorkerPreset[]>([]);
const selectedPresetId = ref('');
const name = ref('');
const pricePerHour = ref(0);
const quantity = ref(1);
const defaultSelected = ref(false);

const requesting = ref(false);
const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

async function onSubmit() {
  if (selectedPresetId.value == '') onCreateWorkerPreset();
  else onUpdateWorkerPreset();
}

async function onCreateWorkerPreset() {
  requesting.value = true;
  const response = await API.post<WorkerPreset[]>(PathSegment.WORK_REPORT_POSITION_PRESET, {
    id: '',
    name: name.value,
    pricePerHour: pricePerHour.value,
    quantity: quantity.value,
    defaultSelected: defaultSelected.value
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.CREATED && response.data) {
    const ids = new Set(existingWorkerPresets.value.map(p => p.id));
    const newPreset = response.data.find(preset => !ids.has(preset.id));
    const newId = newPreset ? newPreset.id : '';

    existingWorkerPresets.value = response.data;
    setPresetOptions(response.data);
    $toast.success(`Arbeitervorlage erstellt`);
    selectedPresetId.value = newId;
  } else {
    $toast.error(`Erstellen der Arbeitervorlage fehlgeschlagen: ${response.message}`);
  }
}

async function onUpdateWorkerPreset() {
  requesting.value = true;
  const response = await API.put<WorkerPreset[]>(PathSegment.WORK_REPORT_POSITION_PRESET, {
    id: selectedPresetId.value,
    name: name.value,
    pricePerHour: pricePerHour.value,
    quantity: quantity.value,
    defaultSelected: defaultSelected.value
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.UPDATED && response.data) {
    existingWorkerPresets.value = response.data;
    setPresetOptions(response.data);
    $toast.success(`Arbeitervorlage aktuallisiert`);
  } else {
    $toast.error(`Aktuallisieren der Arbeitervorlage fehlgeschlagen: ${response.message}`);
  }
}

async function onDeleteWorkerPreset() {
  requesting.value = true;
  const selected = selectedPresetId.value
  const response = await API.delete(PathSegment.WORK_REPORT_POSITION_PRESET, selected);
  requesting.value = false;
  if (response && response.key == ResponseKey.DELETED) {
    $toast.success(`Arbeitervorlage gelöscht`);
    selectedPresetId.value = '';
    setPresetOptions(existingWorkerPresets.value.filter(p => p.id != selected));
  } else {
    $toast.error(`Löschen der Arbeitervorlage fehlgeschlagen: ${response.message}`);
  }
}

async function loadPresets() {
  loading.value = true;
  const response = await API.getDataObject<WorkerPreset[]>(PathSegment.WORK_REPORT_POSITION_PRESET);
  loading.value = false;
  if (!response) return;
  existingWorkerPresets.value = response;
  setPresetOptions(response);
}

function setPresetOptions(presets: WorkerPreset[]) {
  presetOptions.value = [{label: 'Neue Vorlage', value: ''}, ...(presets.map(p => {return {label: p.name, value: p.id}}))]
}

watch(() => selectedPresetId.value, newVal => {
  if (!newVal || newVal == '') {
    name.value = '';
    pricePerHour.value = 0;
    quantity.value = 0;
    defaultSelected.value = false;
  } else {
    const preset = existingWorkerPresets.value.find(p => p.id == newVal);
    if (!preset) return;
    name.value = preset.name;
    pricePerHour.value = preset.pricePerHour;
    quantity.value = preset.quantity;
    defaultSelected.value = preset.defaultSelected;
  }
}, {
  immediate: true
});

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

onMounted(() => {
  loadPresets();
})
</script>