<template>

  <base-site :title="titleText" :show-button="!!projectId" button-icon="delete" button-look="secondary-light" @button-action="openModal">
    <base-container class="mb-betweenElements">
        <FormKit v-if="!customerId && !projectId" type="select" name="customerId" id="customerId" label="Kunde" :options="customerOptions" v-model="customerIdFromOptions" />
        <FormKit type="text" name="name" id="name" label="Projektname*" validation="required" v-model="projectName" />

        <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Rechnungsadresse</base-heading>
        <FormKit type="checkbox" name="person" id="person" label="gleich wie Kundenadresse" v-model="addressEqualsCustomerAddress" />
        <FormKit v-if="!addressEqualsCustomerAddress" type="group" name="address" id="address">
          <FormKit type="text" name="street" id="street" label="Strasse*" v-model="projectAddressStreet" />
          <div class="flex flex-col md:flex-row">
            <div class="md:flex-3 md:mr-betweenElements"><FormKit type="text" name="zip" id="zip" label="PLZ*" v-model="projectAddressZip" /></div>
            <div class="md:flex-1"><FormKit type="text" name="city" id="city" label="Ort*" v-model="projectAddressCity" /></div>
          </div>
          <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
            <FormKit type="text" name="state" id="state" label="Kanton" v-model="projectAddressState" :classes="{outer: 'flex-1 mb-0'}" />
            <FormKit type="text" name="country" id="country" label="Land" v-model="projectAddressCountry" :classes="{outer: 'flex-1 mb-0'}" />
          </div>
        </FormKit>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Projekt löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll das Projekt wirklich gelöscht werden?</confirmation-modal>
    </base-container>

    <div class="flex md:flex-row flex-col">
      <base-button look="primary" class="md:mr-auto mb-betweenElements md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button v-if="projectId" look="secondary-light" class="md:mx-0 mx-betweenElements mb-betweenElements md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>

  </base-site>

</template>

<script setup lang="ts">

import { API, PathSegment } from '@/client/axios'
import { useRoute } from 'vue-router'
import { ResponseKey } from '@/model/ResponseWrapper'
import router, { routeNames } from '@/router'
import { onMounted, ref } from 'vue'
import type Customer from '@/model/Customer'
import type Project from '@/model/Project'
import BaseSite from '@/components/base/BaseSite.vue'
import { useToast } from 'vue-toast-notification'
import { TenantCompany } from '@/stores/TenantCompany'
import BaseButton from '@/components/base/BaseButton.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'

const PROJECT = 'Projekt';
const route = useRoute();

const requesting = ref(false);
const projectId = route.params.id as string;
const project = ref<Project>();
const customerId = route.query.customerId as string;
const titleText = ref('Projekt');
const submitText = ref<string>();

const customerOptions = ref<{value: string, label: string}[]>([]);
const customerIdFromOptions = ref('');
const projectName = ref<string>();
const projectAddressStreet = ref<string>();
const projectAddressCity = ref<string>();
const projectAddressZip = ref<string>();
const projectAddressState = ref<string>();
const projectAddressCountry = ref<string>();

const addressEqualsCustomerAddress = ref(true);

const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast()

async function getCustomerOptions() {
  try {
    const response = await API.get<Customer[]>(PathSegment.COMPANIES, TenantCompany.getCompany().id, PathSegment.CUSTOMERS);
    if (response.key == ResponseKey.OK) {
      customerOptions.value = [{value: '', label: 'Kunde wählen'}, ...response.data.map(c => {
        const label = c.companyName ? c.companyName : c.firstname + c.lastname;
        return { value: c.id, label: label };
      })];
    } else {
      console.error(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

async function onSubmit() {
  const fields: any = {
    customerId: customerId ? customerId : customerIdFromOptions.value,
    name: projectName.value,
    address: {
      street: projectAddressStreet.value,
      city: projectAddressCity.value,
      zip: projectAddressZip.value,
      state: projectAddressState.value,
      country: projectAddressCountry.value
    }
  }
  if (projectId) await onUpdate(fields);
  else await onCreate(fields);
}

async function onCreate(fields: any) {
  if (requesting.value || !checkAddress(fields)) return;
  fields = cleanFields(fields);
  if (customerId) {
    fields.customerId = customerId;
  }
  if (!fields.customerId || fields.customerId == '') {
    $toast.info(`Kein Kunde ausgewählt`);
    console.error('Customer not selected!');
    return;
  }

  requesting.value = true;
  const response = await API.createDataObject<Project>(PathSegment.PROJECTS, fields, PROJECT);
  if (response) await router.push({name: routeNames.PROJECT, params: {id: response.id}});
  requesting.value = false;
}

async function onUpdate(fields: any) {
  if (requesting.value || !checkAddress(fields)) return;
  fields = cleanFields(fields);
  fields.id = projectId;
  fields.customerId = project.value?.customerId;
  if (fields.address && project.value?.address) {
    fields.address.id = project.value?.address.id;
  }

  requesting.value = true;
  const response = await API.updateDataObject<Project>(PathSegment.PROJECTS, fields, PROJECT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.PROJECTS, projectId, PROJECT);
  if (success) {
    await router.push({name: routeNames.CUSTOMER, params: {id: project.value?.customerId}});
  }
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

function cleanFields(fields: any) {
  if (addressEqualsCustomerAddress.value) return {customerId: fields.customerId, name: fields.name};
  return fields;
}

function checkAddress(fields: any) {
  if (!addressEqualsCustomerAddress.value && (!fields.address.street || !fields.address.zip || !fields.address.city)) {
    $toast.info('Adresse nicht vollständig ausgefüllt');
    return false;
  }
  return true;
}

async function loadProject() {
  try {
    const response = await API.get<Project>(PathSegment.PROJECTS, projectId);
    if (response.key == ResponseKey.OK) {
      project.value = response.data;
      projectName.value = response.data.name;
      if (response.data.address) {
        projectAddressStreet.value = response.data.address.street;
        projectAddressCity.value = response.data.address.city;
        projectAddressZip.value = response.data.address.zip;
        projectAddressState.value = response.data.address.state;
        projectAddressCountry.value = response.data.address.country;
        addressEqualsCustomerAddress.value = false;
      }
    } else {
      $toast.error(`Projekt konnte nicht geladen werden: ${response.message}`);
      console.error(response.message);
    }
  } catch (error) {
    $toast.error(`Projekt konnte nicht geladen werden`);
    console.error(error);
  }
}

onMounted(async () => {
  if (projectId) {
    titleText.value = 'Projekt bearbeiten'
    submitText.value = 'Projekt speichern';
    await loadProject();
  } else {
    console.log(!customerId)
    if (!customerId) {
      await getCustomerOptions();
    }
    titleText.value = 'Projekt erstellen';
    submitText.value = 'Projekt erfassen';
  }
});

</script>

<style scoped>

</style>