<template>

  <base-site title="Benutzerprofil">

    <base-container>

      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>person</base-icon>
        </div>
        {{ KeycloakService.getUserName() == '' ? 'Profilaktionen' : KeycloakService.getUserName() }}
      </base-heading>

      <div class="flex flex-col md:flex-row">
        <base-button class="mb-betweenElements md:w-1/2 w-full md:mr-betweenElements" look="secondary" @click="doAction('UPDATE_PROFILE')">Benutzerdaten aktualisieren</base-button>
        <base-button class="mb-betweenElements md:w-1/2 w-full" look="secondary" @click="doAction('UPDATE_PASSWORD')">Passwort aktualisieren</base-button>
      </div>
      <div class="flex flex-col md:flex-row">
        <base-button class="mb-betweenElements md:mb-0 md:w-1/2 w-full md:mr-betweenElements " look="secondary" @click="openModal">Zwei-Faktor-Authentifizierung hinzufügen</base-button>
        <base-button class="md:w-1/2 w-full" look="secondary" @click="KeycloakService.keycloak.accountManagement()">Keycloak Account Management</base-button>
      </div>

      <confirmation-modal ref="confirm2FA"
                          title="2 Faktor Authentifizierung hinzufügen"
                          confirm-text="Weiter"
                          @on-confirm="doAction('CONFIGURE_TOTP')">
        ACHTUNG: Falls Sie bereits eine Zwei-Faktor-Authentifizierung hinzugefügt haben, wird nun ein weitere erstellt. Sie können die erfassten Zwei-Faktor-Authentifizierungen in der Keycloak Account Management Konsole einsehen unter "Kontosicherheit >> Anmeldung"
      </confirmation-modal>

    </base-container>

  </base-site>

</template>

<script setup lang="ts">

import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { KeycloakService } from '../../service/keycloakService'
import BaseButton from '@/components/base/BaseButton.vue'
import { API } from '@/client/axios'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import { ref } from 'vue'

const confirm2FA = ref(ConfirmationModal);

function doAction(action: string) {
  window.location.href = `${KeycloakService.url}/realms/${KeycloakService.realm}/protocol/openid-connect/auth?client_id=${KeycloakService.clientId}&redirect_uri=${API.frontendURL}/profile&response_type=code&scope=openid&kc_action=${action}`;
}

const openModal = () => {
  if (confirm2FA.value) {
    confirm2FA.value.openModal();
  }
};

</script>

<style scoped>

</style>